import { Controller } from '@hotwired/stimulus'
import throttle from '../utilities/throttle.js'

export default class extends Controller {
  static targets = [
    'container',
    'element'
  ]

  static values = {
    displayNoneClass: { type: String, default: 'display-none' },
    visibleHeight: { type: Number, default: 800 }
  }

  connect() {
    this.set_visibility()
    this.resizeObserver = new ResizeObserver(throttle(this.set_visibility.bind(this), 100))
    this.resizeObserver.observe(this.containerTarget)
  }

  disconnect() {
    this.resizeObserver.disconnect()
  }

  set_visibility() {
    const containerHeight = this.containerTarget.offsetHeight
    if (containerHeight < this.visibleHeightValue) {
      this.elementTarget.classList.add(this.displayNoneClassValue)
    } else {
      this.elementTarget.classList.remove(this.displayNoneClassValue)
    }
  }
}
