import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input']
  static values = {
    url: String,
    wholeForm: Boolean,
    count: Number,
    reFocus: { type: Boolean, default: true }
  }

  isRunning = false
  isQueued = false

  async perform(
    { currentTarget: { name, value, tomselect }, params: { url: urlParam, value: valueParam, additionalQuery } }
  ) {
    if (this.isRunning && this.isQueued) return
    if (this.isRunning) {
      this.isQueued = true
      return
    }

    this.isRunning = true

    // eslint-disable-next-line no-param-reassign
    if (tomselect) value = tomselect.items

    if (!this.reFocusValue) {
      document.activeElement.blur()
    }

    const fetchURL = new URL(urlParam || this.urlValue)
    const query = new URLSearchParams(fetchURL.search)

    if (additionalQuery) {
      Object.keys(additionalQuery).forEach((key) => query.append(key, additionalQuery[key]))
    }

    if (this.wholeFormValue) {
      const form = new FormData(this.element)
      form.forEach((formValue, key) => query.append(key, formValue))
    } else if (this.hasInputTarget) {
      this.inputTargets.forEach((input) => query.set(input.name, this.#valueFrom(input)))
    } else {
      query.set(name, valueParam ?? value)
    }

    const response = await get(fetchURL, { query, responseKind: 'turbo-stream' })

    this.isRunning = false
    if (this.isQueued) {
      this.isQueued = false
      this.perform({
        currentTarget: { name, value, tomselect }, params: { url: urlParam, value: valueParam, additionalQuery }
      })
    }

    if (response.ok) this.countValue += 1
  }

  #valueFrom(input) {
    if (input.type === 'checkbox') {
      return input.checked
    }
    if (input.tomselect) {
      return input.tomselect.items
    }
    if (input.multiple) {
      return [...input.selectedOptions].map((o) => o.value)
    }

    return input.value
  }
}
