import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    focus: { type: Boolean, default: true }
  }

  connect() {
    if (this.focusValue) {
      this.focusInput()
    }
  }

  focusInput() {
    if (this.hasInputTarget) {
      setTimeout(() => {
        if (this.inputTarget.dataset.tomSelectTarget) {
          document.getElementById(`${this.inputTarget.id}-ts-control`).focus()
        } else {
          this.inputTarget.focus()
        }
      }, 100)
    }
  }
}
