import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    minWidth: {
      type: String,
      default: '512px'
    },
    attribute: {
      type: String,
      default: 'open'
    }
  }

  connect() {
    const mql = window.matchMedia(`(width > ${this.minWidthValue})`)
    this.element[this.attributeValue] = mql.matches
  }
}
