import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['iframe']
  static values = {
    url: { type: String, default: '' }
  }

  print() {
    if (this.hasIframeTarget) {
      // If the iframe was set to lazy load, make it eager before we try to print,
      // otherwise it might not have been loaded yet
      this.iframeTarget.loading = 'eager'
      this.iframeTarget.contentWindow.print()
    } else {
      window.print()
    }
  }

  open_url_and_print() {
    const win = window.open(this.urlValue, '_blank')

    win.onload = function () {
      win.print()

      // This won't work if content-type is anything other than HTML
      win.onafterprint = function () {
        win.close()
      }
    }
  }
}
