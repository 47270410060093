import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['watch']
  static values = {
    rootMargin: String,
    message: String
  }

  connect() {
    useIntersection(this, {
      element: this.watchTarget,
      rootMargin: this.rootMarginValue
    })
  }

  appear() {
    this.watchTarget.click()
    if (this.hasMessageValue) {
      this.watchTarget.textContent = this.messageValue
      this.watchTarget.classList.add('btn--disabled')
    }
  }
}
